<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <div class="withdrawal-content">
      <div class="withdrawal-title a-fs-16 a-c-master a-fw-700">提现流程</div>
      <div class="step-box">
        <el-steps :active="stepAc" align-center>
          <el-step>
            <img
              v-if="stepAc == 0"
              slot="icon"
              src="../../assets/icon/wait-icon.png"
              style="width: 15px; height: 15px"
              alt=""
            />
            <div v-else-if="stepAc > 0" slot="icon" class="step-spot1"></div>
            <div v-else slot="icon" class="step-spot"></div>
            <span slot="title" class="a-fs-14">申请提现</span>
          </el-step>
          <el-step title="步骤 2">
            <img
              v-if="stepAc == 1"
              slot="icon"
              src="../../assets/icon/wait-icon.png"
              style="width: 15px; height: 15px"
              alt=""
            />
            <div v-else-if="stepAc > 1" slot="icon" class="step-spot1"></div>
            <div v-else slot="icon" class="step-spot"></div>
            <span slot="title" class="a-fs-14">审核中</span>
          </el-step>
          <el-step title="步骤 3">
            <img
              v-if="stepAc == 2"
              slot="icon"
              src="../../assets/icon/wait-icon.png"
              style="width: 15px; height: 15px"
              alt=""
            />
            <div v-else-if="stepAc > 2" slot="icon" class="step-spot1"></div>
            <div v-else slot="icon" class="step-spot"></div>
            <span slot="title" class="a-fs-14">转账中</span>
          </el-step>
          <el-step title="步骤 3">
            <img
              v-if="stepAc == 3"
              slot="icon"
              src="../../assets/icon/wait-icon.png"
              style="width: 15px; height: 15px"
              alt=""
            />
            <div v-else-if="stepAc > 3" slot="icon" class="step-spot1"></div>
            <div v-else slot="icon" class="step-spot"></div>
            <span slot="title" class="a-fs-14">到账</span>
          </el-step>
        </el-steps>
      </div>
      <div class="withdrawal-title a-fs-16 a-c-master a-fw-700 a-mt-24">提现信息</div>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-position="left"
        label-width="140px"
        style="margin-left: 23px"
      >
        <el-form-item label="银行账户" prop="bankAccountId">
          <el-select v-model="form.bankAccountId" placeholder="请选择" style="width: 360px">
            <el-option
              v-for="item in bankList"
              :key="item.id"
              :label="item.bankName ? item.bankName : item.bankBranch"
              :value="item.id"
            >
              <div class="a-flex-rfsc">
                <span v-if="item.bankName" style="padding-right: 20px">{{ item.bankName }}</span>
                <el-tooltip effect="dark" :content="item.bankBranch" placement="top">
                  <span v-if="!item.bankName" class="bankName" style="padding-right: 20px">
                    {{ item.bankBranch }}
                  </span>
                </el-tooltip>
                <span>{{ item.accountNumber }}</span>
              </div>
            </el-option>
            <div class="a-flex-rfsc addBankBtn" @click="toAddCard">
              <i class="el-icon-plus a-fw-700"></i>
              <span class="a-plr-7">添加银行卡</span>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item label="提现金额（元）" prop="amount">
          <el-input
            v-model="form.amount"
            style="width: 360px"
            placeholder="请输入"
            @input="form.amount = checkMoney(form.amount)"
          >
            <span
              slot="suffix"
              class="a-fs-14 a-c-blue"
              style="cursor: pointer"
              @click="allWithdrawal"
            >
              全部
            </span>
          </el-input>
          <div class="a-c-second a-fs-12">
            今日已申请提现{{ util.numFormat(todayWithdrawAmount) }}元，剩余额度{{
              util.numFormat(remainingLimit)
            }}元
          </div>
        </el-form-item>
        <el-form-item label="是否提供发票" prop="supportInvoice">
          <el-select v-model="form.supportInvoice" placeholder="请选择" style="width: 360px">
            <el-option label="不提供发票" :value="0"></el-option>
            <el-option label="提供发票" :value="1"></el-option>
            <el-option label="后续补充发票" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.supportInvoice !== 0"
          label="提供发票日期"
          prop="supportInvoiceDate"
        >
          <le-input-date
            v-model="form.supportInvoiceDate"
            class="supportInvoiceDate"
            label=""
          ></le-input-date>
        </el-form-item>
        <el-form-item v-if="form.supportInvoice !== 0" label="上传发票" prop="invoiceImage">
          <le-upload-file-img ref="pic" v-model="form.invoiceImage"></le-upload-file-img>
        </el-form-item>
      </el-form>
      <div style="height: 100px"></div>
    </div>
    <div class="a-line-t-e0 footerBox">
      <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">
        &nbsp;&nbsp;&nbsp;提现&nbsp;&nbsp;&nbsp;
      </el-button>
      <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancel">
        &nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;
      </el-button>
      <span class="a-fs-14 a-c-normal a-ml-11">工作日17:00前提交，当日审核</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import util from "../../../src/utils/util";
export default {
  name: "Withdrawal",

  data() {
    const checkInvoiceDate = (rule, value, callback) => {
      if (this.form.supportInvoice != 0 && !value) {
        callback(new Error("请选择发票提供日期"));
      } else {
        callback();
      }
    };
    const checkInvoiceImg = (rule, value, callback) => {
      if (this.form.supportInvoice == 1 && !value) {
        callback(new Error("请上传发票"));
      } else {
        callback();
      }
    };
    const checkAmount = (rule, value, callback) => {
      const val = Number((value * 100).toFixed(0));
      if (val > this.remainingLimit) {
        callback(new Error("剩余额度不足"));
      } else if (val > this.userBalance) {
        callback(new Error("当前商户余额不足"));
      } else {
        callback();
      }
    };

    return {
      util: util,
      stepAc: 0,
      bankList: [], // 银行账户列表
      form: {
        bankAccountId: "",
        amount: "",
        supportInvoice: "",
        supportInvoiceDate: "",
        invoiceImage: "",
        userId: "",
      },
      rules: {
        bankAccountId: [{ required: true, message: "请选择银行账户", trigger: "change" }],
        amount: [
          { required: true, message: "请输入提现金额", trigger: "blur" },
          { validator: checkAmount, trigger: "blur" },
        ],
        supportInvoice: [{ required: true, message: "请选择是否提供发票", trigger: "change" }],
        supportInvoiceDate: [{ validator: checkInvoiceDate, trigger: "blur" }],
        invoiceImage: [{ validator: checkInvoiceImg, trigger: "change" }],
      },
      todayWithdrawAmount: "", //今日已提现金额
      remainingLimit: "", //剩余提现额度
      userBalance: "", //商户余额
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
      user: (state) => state.user.user,
    }),
  },
  mounted() {
    this.userBalance = this.$route.query.userBalance;
    this.getBankAccountList();
    this.getTodayWithdrawAmount();
    this.getTodayBalance();
  },
  methods: {
    ...mapActions({
      getCompanyInfo: "company/getCompanyInfo",
    }),
    // 获取银行账户列表
    getBankAccountList() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getBankAccountList,
          method: "get",
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.bankList = res.result.data;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    getTodayWithdrawAmount() {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.getTodayWithdrawAmount,
          method: "post",
          params: {},
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.todayWithdrawAmount = res.result.data;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    // 获取今日额度
    getTodayBalance() {
      this.getCompanyInfo().then((res) => {
        this.remainingLimit = res.balance;
      });
    },
    // 全部
    allWithdrawal() {
      const val =
        this.remainingLimit - this.userBalance > 0 ? this.userBalance : this.remainingLimit;
      this.form.amount = (val / 100).toFixed(2);
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认提交提现申请?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            const reqData = JSON.parse(JSON.stringify(this.form));
            reqData.amount = Number((reqData.amount * 100).toFixed(0));
            this.$Axios
              ._post({
                url: this.$Config.apiUrl.applyWithdraw,
                method: "post",
                params: reqData,
              })
              .then((res) => {
                if (res.result.code == 0) {
                  // this.todayWithdrawAmount = res.result.data
                  this.$router.push({
                    path: "/businessCenter/withdrawal-success",
                    query: {
                      withdrawId: res.result.data,
                    },
                  });
                } else {
                  this.$message.error(res.result.message);
                }
              });
          });
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.$router.back();
    },
    checkMoney(value) {
      value = value.replace(/[^\d.]/g, "");
      //必须保证第一个为数字而不是.
      value = value.replace(/^\./g, "");
      //保证只有出现一个.而没有多个.
      value = value.replace(/\.{2,}/g, ".");
      //保证.只出现一次，而不能出现两次以上
      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      //只能输入两位小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      return value;
    },
    toAddCard() {
      this.$router.push({
        path: "/businessCenter/bank-card-add",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.withdrawal-content {
  height: 100%;
  overflow-y: auto;
  background: #ffffff;
  font-size: 14px;
}
.bankName {
  width: 120px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.withdrawal-title {
  padding: 23px;
}
.step-spot {
  width: 8px;
  height: 8px;
  background: #adb0b3;
  border-radius: 50%;
}
.step-spot1 {
  width: 8px;
  height: 8px;
  background: #007aff;
  border-radius: 50%;
}
/deep/ .el-step__icon.is-text {
  border: none;
}
.addBankBtn {
  padding: 0 20px;
  font-size: 14px;
  color: #007aff;
  line-height: 34px;
  cursor: pointer;
}
/deep/ .supportInvoiceDate {
  padding: 0 !important;
  .date-range {
    margin: 0 !important;
  }
}
.footerBox {
  left: 255px;
  right: 43px;
  position: fixed;
  bottom: 0;
  background: #fff;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
</style>
